<template>
  <ProductList />
</template>

<script>
import ProductList from "@/components/productlist/ProductList.vue";

export default {
  name: "shop",
  components: {
    ProductList,
  },
};
</script>
