<template>
  <section class="lh-WrapperSingle">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--full">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Breadcrumb">
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/"
                >Accueil</router-link
              >
            </li>
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/boutique"
                >Boutique</router-link
              >
            </li>
          </ul>
          <div class="lh-ProductList">
            <div class="lh-ProductList-filter">
              <button class="lh-ProductList-filterButton" @click="filter">
                <svg class="lh-ProductList-filterButtonSvg">
                  <use xlink:href="#filter"></use>
                </svg>
                <span v-if="isActiveLayerFilter">Fermer</span>
                <span v-else>Filtres</span>
              </button>
              <transition name="fade">
                <div
                  class="lh-ProductList-filterLayer"
                  v-if="isActiveLayerFilter"
                >
                  <div class="lh-ProductList-filterLayerContainer">
                    <ul class="lh-ProductList-filterList">
                      <li class="lh-ProductList-filterListItem">
                        <p>Disponibilité</p>
                        <div class="lh-Form-checkbox">
                          <input
                            type="checkbox"
                            id="Disponible"
                            name="Disponible"
                            v-model="isSold"
                            value="false"
                          />
                          <label for="Disponible">Disponible</label>
                        </div>
                      </li>
                      <li class="lh-ProductList-filterListItem">
                        <p>Version</p>
                        <div class="lh-Form-checkbox">
                          <input
                            type="radio"
                            id="version0"
                            name="versions"
                            v-model="version"
                            value="0"
                          />
                          <label for="version0">0</label>
                        </div>
                        <div class="lh-Form-checkbox">
                          <input
                            type="radio"
                            id="version1"
                            name="versions"
                            v-model="version"
                            value="1"
                          />
                          <label for="version1">1</label>
                        </div>
                      </li>
                      <li class="lh-ProductList-filterListItem">
                        <p>Couleur</p>
                        <div class="lh-Form-checkbox">
                          <input
                            type="checkbox"
                            id="ivory"
                            name="colors"
                            v-model="color"
                            value="ivory"
                          />
                          <label for="ivory">Blanc ivoire</label>
                        </div>
                        <div class="lh-Form-checkbox">
                          <input
                            type="checkbox"
                            id="silk"
                            name="colors"
                            v-model="color"
                            value="silk"
                          />
                          <label for="silk">Blanc soie</label>
                        </div>
                        <div class="lh-Form-checkbox">
                          <input
                            type="checkbox"
                            id="granite"
                            name="colors"
                            v-model="color"
                            value="granite"
                          />
                          <label for="granite">Gris granite</label>
                        </div>
                        <div class="lh-Form-checkbox">
                          <input
                            type="checkbox"
                            id="blanc"
                            name="colors"
                            v-model="color"
                            value="blanc"
                          />
                          <label for="blanc">Blanc neige</label>
                        </div>
                        <div class="lh-Form-checkbox">
                          <input
                            type="checkbox"
                            id="transparent"
                            name="colors"
                            v-model="color"
                            value="transparent"
                          />
                          <label for="transparent"
                            >Translucide phosphorescent</label
                          >
                        </div>
                      </li>
                      <li class="lh-ProductList-filterListItem">
                        <p>Couleur du cordon</p>
                        <div class="lh-Form-checkbox">
                          <input
                            type="radio"
                            id="whiteWire"
                            name="wirecolor"
                            v-model="wireColor"
                            value="Blanc"
                          />
                          <label for="whiteWire">Blanc</label>
                        </div>
                        <div class="lh-Form-checkbox">
                          <input
                            type="radio"
                            id="transWire"
                            name="wirecolor"
                            v-model="wireColor"
                            value="Translucide"
                          />
                          <label for="transWire">Translucide</label>
                        </div>
                      </li>
                    </ul>
                    <transition name="fade">
                      <button
                        v-if="isActiveFilter"
                        @click="renit"
                        class="lh-ProductList-renitFilterButton"
                      >
                        Rénitialiser
                      </button>
                    </transition>
                  </div>
                  <transition name="fade">
                    <div
                      @click="filter"
                      class="js-lh-ProductList-filterLayerClose lh-ProductList-filterLayerClose"
                      v-if="isActiveLayerFilter"
                    ></div>
                  </transition>
                </div>
              </transition>
            </div>
            <ul class="lh-ProductList-list">
              <li
                class="lh-ProductList-listItem js-lh-ProductList-listItem"
                v-for="(product, index) in computedProducts"
                :key="product.id"
              >
                <router-link
                  class="lh-ProductList-listItemLink"
                  :to="{ name: 'product', params: { id: product.id } }"
                >
                  <div class="lh-ProductList-topMobile">
                    <div class="lh-ProductList-lampPart">
                      <picture class="lh-ProductList-lampPartPicture">
                        <source
                          :srcset="
                            makeWebpImagePath(
                              product,
                              product.isNightOn && product.isGlowNight
                            )
                          "
                          type="image/webp"
                        />
                        <source
                          :srcset="
                            makePngImagePath(
                              product,
                              product.isNightOn && product.isGlowNight
                            )
                          "
                          type="image/png"
                        />
                        <img
                          class="lh-ProductList-lampPartImg lazyload"
                          :alt="'Photo lampe LHight #' + product.id"
                          :data-src="
                            makePngImagePath(
                              product,
                              product.isNightOn && product.isGlowNight
                            )
                          "
                          loading="lazy"
                        />
                      </picture>
                      <div
                        class="lh-ProductList-lampPartBg"
                        :class="
                          product.isNightOn && product.isGlowNight
                            ? 'lh-ProductList-lampPartBg--dark'
                            : ''
                        "
                      >
                        <button
                          arial-label="voir dans la nuit"
                          v-if="product.isGlowNight"
                          class="lh-ProductList-toggleNight"
                          @click="toggleNight($event, product)"
                        >
                          <svg class="lh-ProductList-toggleNightSvg">
                            <use xlink:href="#night"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <ul
                      class="lh-ProductList-bgList js-lh-ProductList-bgList"
                      :data-isodd="isOdd(index)"
                      :data-colorscount="product.colors.length"
                    >
                      <li
                        class="lh-ProductList-bgListItem js-lh-ProductList-bgListItem"
                        v-for="(color, indexColor) in product.colors"
                        :key="product.id + indexColor"
                        :style="getJpgImagePath(product, indexColor)"
                      ></li>
                    </ul>
                  </div>
                  <div class="lh-ProductList-infos">
                    <ul class="lh-ProductList-infosList">
                      <li class="lh-ProductList-infosListItem">
                        <span class="lh-ProductList-listItemTitle"
                          >LHight #{{ product.id }}</span
                        >
                      </li>
                      <li class="lh-ProductList-infosListItem">
                        {{ product.colorName }}
                      </li>
                      <li class="lh-ProductList-infosListItem">
                        <span class="lh-Tooltip-trigger"
                          >Version {{ product.version }}</span
                        >
                        <span
                          class="lh-Tooltip-content"
                          data-position="topright"
                        >
                          Outre les couleurs, il existe deux versions dans
                          lesquels la disposition de la main gauche (formant le
                          «H») diffère.
                          <br />
                          <br />
                          <div v-if="!product.version != 0">
                            Version 0: dos de la main.
                          </div>
                          <div v-else>
                            Version 1: main retournée, paume de face.
                          </div>
                        </span>
                      </li>
                      <li class="lh-ProductList-infosListItem">
                        <span
                          class="lh-ProductList-infosListItem--price"
                          v-if="!product.isSold"
                          >{{ product.price }}€</span
                        >
                        <span class="lh-ProductList-infosListItem--price" v-else
                          >VENDUE</span
                        >
                      </li>
                      <li class="lh-ProductList-infosListItem">
                        <span class="lh-ProductList-link">Voir la lampe</span>
                      </li>
                    </ul>
                  </div>
                </router-link>
              </li>
            </ul>

            <div
              class="lh-ProductList-noResult"
              v-if="computedProducts.length == 0"
            >
              <p>Désolé aucune LHight ne correspond à vos critères.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { imagePath } from "@/mixins/imagePath.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ProductList",
  data: () => {
    return {
      isActiveLayerFilter: false,
      wireColor: [],
      version: [],
      isSold: [],
      color: [],
      isNightOn: false,
    };
  },
  mixins: [imagePath],
  computed: {
    computedProducts() {
      return this.$store.state.products.filter((item) => {
        if (this.color.length) {
          const filterColor = String(this.color).split(",");

          for (var i = 0; i < filterColor.length; i++) {
            if (!item.colors.includes(filterColor[i])) {
              return false;
            }
          }
        }
        return (
          (this.wireColor.length === 0 ||
            this.wireColor.includes(item.wireColor)) &&
          (this.version.length === 0 || this.version == item.version) &&
          (this.isSold.length === 0 || this.isSold == String(item.isSold))
        );
      });
    },

    isActiveFilter() {
      return (
        this.color.length ||
        this.wireColor.length ||
        this.version.length ||
        this.isSold.length
      );
    },
  },

  watch: {
    computedProducts() {
      this.animItem();
    },
  },

  mounted() {
    this.animItem();
    this.initZIndex();
  },

  created() {
    this.animItem();
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    ScrollTrigger.getAll().forEach((t) => t.kill());
  },

  methods: {
    getJpgImagePath(product, index) {
      let extension = "jpg";
      if (document.body.classList.contains("webp")) {
        extension = "webp";
      }
      const img = require(`@/assets/images/colors/${product.colors[index]}.${extension}`);
      return `background-image: url(${img})`;
    },

    handleScroll() {
      const products = document.querySelectorAll(".js-lh-ProductList-listItem");

      products.forEach((product) => {
        const productColorsImgList = product.querySelectorAll(
          ".js-lh-ProductList-bgListItem"
        );

        productColorsImgList.forEach((img) => {
          const getPercent = Math.round(
            (img.getBoundingClientRect().top * 100) / window.innerHeight
          );

          img.style.backgroundPosition = `50% ${getPercent}%`;
        });
      });
    },

    animItem() {
      setTimeout(() => {
        gsap.to(".js-lh-ProductList-listItem", {
          autoAlpha: 0,
          y: "80px",
        });
        ScrollTrigger.batch(".js-lh-ProductList-listItem", {
          start: "top 85%",
          onEnterBack: (batch) =>
            gsap.to(batch, {
              autoAlpha: 1,
              y: 0,
              stagger: 0.2,
              overwrite: true,
            }),
          onEnter: (batch) =>
            gsap.to(batch, {
              autoAlpha: 1,
              y: 0,
              stagger: 0.2,
            }),
        });
      }, 0);
    },

    initZIndex() {
      const productCount = document.querySelectorAll(
        ".js-lh-ProductList-listItem"
      );

      for (var i = 0; i < productCount.length; i++) {
        productCount[i].style.zIndex = productCount.length + 1 - i;
      }
    },

    isOdd(index) {
      if (index % 2) {
        return true;
      }
      return false;
    },

    renit() {
      gsap.to(".js-lh-ProductList-listItem", {
        autoAlpha: 0,
        y: "80px",
      });
      const radios = document.getElementsByTagName("input");
      for (let i = 0; i < radios.length; i++) {
        radios[i].checked = false;
      }
      this.wireColor = [];
      this.version = [];
      this.isSold = [];
      this.color = [];
    },

    filter() {
      const app = document.getElementById("app");
      this.isActiveLayerFilter = !this.isActiveLayerFilter;

      if (app.classList.contains("lh-NoScroll")) {
        app.classList.remove("lh-NoScroll");
      } else {
        app.classList.add("lh-NoScroll");
      }
    },

    toggleNight(e, product) {
      e.preventDefault();
      product.isNightOn = !product.isNightOn;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@import "@/components/productlist/_index.scss";
</style>
